<div class="wrapper">
    <div class="main-panel">
        <!-- BEGIN : Main Content-->
        <div class="main-content">
            <div class="content-overlay"></div>
            <div class="content-wrapper p-0" >

                <!--Login Page Starts-->
                <section id="login">
                    <div class="row auth-height full-height-vh m-0">
                        <div class="col-12 d-flex align-items-center justify-content-center">

                            <div class="card overflow-hidden">
                                <div class="card-content">
                                    <div class="card-body auth-img">
                                        <div class="row m-0">
                                            <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center auth-img-bg p-3">
                                                <img src="assets/img/gallery/login.png" alt="" class="img-fluid" width="300" height="230">
                                            </div>
                                            
                                            <div class="col-lg-6 col-12 px-4 py-3">
                                                <div class="logo-img" style="text-align: center;"><img class="logo-img" alt="WRCMS" [src]="logoUrl" style="max-width: 100px; padding-bottom: 2rem;"></div>

                                                <h4 class="mb-2 card-title">Login</h4>
                                                <p>Welcome back, please login to your account.</p>
                                                <!-- <ngb-alert type="light-danger" class="mb-2" *ngIf="isLoginFailed" (close)="isLoginFailed = false">
                                                    <p class="mb-0">Login failed!</p>
                                                </ngb-alert> -->
                                                
                                                <form [formGroup]="loginForm" (ngSubmit)="login()">
                                                    <div class="form-group">
                                                        <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" placeholder="Email / Username">
                                                        <div *ngIf="submitted && f.username.errors">
                                                            <span class="text-error" *ngIf="f.username.errors.required">Email / Username is required</span>
                                                        </div>
                                                    </div>
                        
                                                    <div class="form-group">
                                                        <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="Enter Password">
                                                        <div *ngIf="submitted && f.password.errors">
                                                            <span class="text-error" *ngIf="f.password.errors.required">Password is required</span>
                                                            <span class="text-error" *ngIf="f.password.errors.minlength">Password is too short (minimum 6 characters required)</span>
                                                        </div>
                                                    </div>
                        
                                                    <div class="text-center">
                                                        <button type="submit" class="btn btn-primary "><a class="text-decoration-none text-white">Login</a></button>
                                                    </div>
                                                </form>
                                                
                                                <hr>

                                                <div class="d-flex justify-content-between align-items-center pull-right">
                                                    <div class="forgot-password-option">
                                                        <a [routerLink]="['/forgot-password']" class="text-decoration-none text-primary">Forgot Password ?</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <!--Login Page Ends-->
            </div>
        </div>
    </div>
</div>