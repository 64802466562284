import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from "./layouts/full/full-layout.component";
// import { ContentLayoutComponent } from "./layouts/content/content-layout.component";

import { Full_ROUTES } from "./shared/routes/full-layout.routes";
// import { CONTENT_ROUTES } from "./shared/routes/content-layout.routes";
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

// import { AuthGuard } from './shared/auth/auth-guard.service';

const appRoutes: Routes = [
    {path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    // { path: '', component: FullLayoutComponent, data: { title: 'full Views' }, children: Full_ROUTES, canActivate: [AuthGuard] },
    { path: '', component: FullLayoutComponent, data: { title: 'full Views' }, children: Full_ROUTES, canActivate: [] },
    { path: 'login', component: LoginComponent, data: { title: "Login" } },
    { path: 'forgot-password', component: ForgotPasswordComponent, data: { title: "Forgot Password" } },
    // { path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES },
    { path: '**', redirectTo: 'page/error' }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
