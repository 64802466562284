import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { RestService } from '../services/rest.service';
import { Router, NavigationExtras } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	logoUrl = 'assets/img/logo.png';
	loginForm: FormGroup;
	submitted = false;

	public token: string;

	constructor(
		private rest: RestService,
		private router: Router,
		private formBuilder: FormBuilder,
		private alertService: ToastrService,
		private renderer: Renderer2,
		@Inject(DOCUMENT) private document: Document,
	) {
		this.renderer.addClass(this.document.body, "auth-page");
		this.renderer.removeClass(this.document.body, "layout-dark");
      	this.renderer.removeClass(this.document.body, "layout-transparent");
	}

	ngOnInit() {
		this.rest.checkAuthentication().then((res) => {
			this.router.navigateByUrl('/');
		}, (err) => { });

		this.loginForm = this.formBuilder.group({
			username: ['', Validators.required],
			password: ['', [Validators.required, Validators.minLength(6)]]
		});
	}

	get f() { return this.loginForm.controls; }

	login() {

		this.submitted = true;
		if (this.loginForm.invalid) {
			return;
		}

		this.rest.login(this.loginForm.value).then((result) => {
			if(result['token']) {
				this.alertService.success('You are successfully logged in', '', { "progressBar": true });

				// Get the redirect URL from our auth service
				// If no redirect has been set, use the default
				// let redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '/admin';
				let redirect = '/';

				// Set our navigation extras object
				// that passes on our global query params and fragment
				let navigationExtras: NavigationExtras = {
					queryParamsHandling: 'preserve',
					preserveFragment: true
				};

				// Redirect the user
				this.router.navigate([redirect], navigationExtras);
			} else {
				this.router.navigateByUrl('/login');
			}
		}, (err) => {
			switch (err['status']) {
				case 300 :
					if(err.error && err.error.message && err.error.message.length ) {
						for (var row in err.error.message) {
							this.alertService.error(err.error.message[row], '', { "progressBar": true });
						}
					}
					break;
				case 401 :
					// Do Nothing , login required
					this.router.navigateByUrl('/login');
					break;
				default :
					this.router.navigateByUrl('/login');
					break;
			}
		});
	}
}
