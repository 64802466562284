import { Component, OnInit, ViewChild, OnDestroy, ElementRef, AfterViewInit, ChangeDetectorRef, HostListener } from "@angular/core";
import { ROUTES } from './vertical-menu-routes.config';
import { HROUTES } from '../horizontal-menu/navigation-routes.config';

import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { customAnimations } from "../animations/custom-animations";
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfigService } from '../services/config.service';
import { Subscription } from 'rxjs';
import { LayoutService } from '../services/layout.service';
import { RestService } from '../../services/rest.service';

@Component({
  selector: "app-sidebar",
  templateUrl: "./vertical-menu.component.html",
  animations: customAnimations
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('toggleIcon') toggleIcon: ElementRef;
    public menuItems: any[];
    level: number = 0;
    logoUrl = 'assets/img/logo.png';
    public config: any = {};
    protected innerWidth: any;
    layoutSub: Subscription;
    configSub: Subscription;
    perfectScrollbarEnable = true;
    collapseSidebar = false;
    resizeTimeout;
    loggedUser: any;

    constructor(
        private router: Router,
        public translate: TranslateService,
        private layoutService: LayoutService,
        private configService: ConfigService,
        private cdr: ChangeDetectorRef,
        private deviceService: DeviceDetectorService,
        private rest: RestService,
    ) {
        this.config = this.configService.templateConf;
        this.innerWidth = window.innerWidth;
        this.isTouchDevice();
    }


    ngOnInit() {
        // this.menuItems = ROUTES;
        this.menuItems = [];

        this.rest.checkAuthentication().then((res) => {
            this.loggedUser = res;

            this.menuItems.push({ path: '/dashboard', title: 'Dashboard', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [ ] });

            if(this.loggedUser.permission.indexOf('data-entries-list') != -1) {
                this.menuItems.push({ path: '/data/list', title: 'Data', icon: 'fa fa-database', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [ ] });
            }

            if(this.loggedUser.permission.indexOf('data-manager-list') != -1) {
                this.menuItems.push({ path: '/data-manager/list', title: 'Media Data', icon: 'fa fa-database', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [ ] });
            }

            if(this.loggedUser.permission.indexOf('report-summery-bill') != -1) {
                this.menuItems.push({ path: '/report/summery-bill', title: 'Summery Bill Report', icon: 'fa fa-bar-chart', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [ ] });
            }
            if(this.loggedUser.permission.indexOf('report-ft') != -1) {
                this.menuItems.push({ path: '/report/ft', title: 'FT Report', icon: 'fa fa-bar-chart', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [ ] });
            }
            if(this.loggedUser.permission.indexOf('report-media-data') != -1) {
                this.menuItems.push({ path: '/report/media-data', title: 'Media Data Report', icon: 'fa fa-bar-chart', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [ ] });
            }

            let adminSubMenu = [];
            if(this.loggedUser.permission.indexOf('user-list') != -1) {
                adminSubMenu.push({ path: '/user/list', title: 'Users', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [ ] });
            }
            if(this.loggedUser.permission.indexOf('permission-list') != -1) {
                adminSubMenu.push({ path: '/user/permissions', title: 'Permissions', icon: 'ft-lock', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [ ] });
            }

            if(adminSubMenu && adminSubMenu.length) {
                this.menuItems.push({ path: '', title: 'Admin', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: adminSubMenu });
            } else {
                if(this.loggedUser.permission.indexOf('user-list') != -1) {
                    this.menuItems.push({ path: '/user/list', title: 'Users', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [ ] });
                }
            }

        }, (err) => {
            this.router.navigateByUrl('/');
        });
    }

    ngAfterViewInit() {
        this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
            if (templateConf) {
                this.config = templateConf;
            }
            this.loadLayout();
            this.cdr.markForCheck();
        });

        this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
        collapse => {
            if (this.config.layout.menuPosition === "Side") {
                this.collapseSidebar = collapse;
            }
        });
    }


    @HostListener('window:resize', ['$event'])
    onWindowResize(event) {
        if (this.resizeTimeout) {
            clearTimeout(this.resizeTimeout);
        }

        this.resizeTimeout = setTimeout((() => {
            this.innerWidth = event.target.innerWidth;
            this.loadLayout();
        }).bind(this), 500);
    }

    loadLayout() {

        // if (this.config.layout.menuPosition === "Top") { // Horizontal Menu
        //     if (this.innerWidth < 1200) { // Screen size < 1200
        //         this.menuItems = HROUTES;
        //     }
        // } else if (this.config.layout.menuPosition === "Side") { // Vertical Menu{
        //     this.menuItems = ROUTES;
        // }

        if (this.config.layout.sidebar.backgroundColor === 'white') {
            this.logoUrl = 'assets/img/logo-dark.png';
        } else {
            this.logoUrl = 'assets/img/logo.png';
        }

        if (this.config.layout.sidebar.collapsed) {
            this.collapseSidebar = true;
        } else {
            this.collapseSidebar = false;
        }
    }

    toggleSidebar() {
        let conf = this.config;
            conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
            this.configService.applyTemplateConfigChange({ layout: conf.layout });

        setTimeout(() => {
            this.fireRefreshEventOnWindow();
        }, 300);
    }

    fireRefreshEventOnWindow = function() {
        const evt = document.createEvent("HTMLEvents");
        evt.initEvent("resize", true, false);
        window.dispatchEvent(evt);
    };

    CloseSidebar() {
        this.layoutService.toggleSidebarSmallScreen(false);
    }

    isTouchDevice() {
        const isMobile = this.deviceService.isMobile();
        const isTablet = this.deviceService.isTablet();

        if (isMobile || isTablet) {
            this.perfectScrollbarEnable = false;
        } else {
            this.perfectScrollbarEnable = true;
        }
    }


    ngOnDestroy() {
        if (this.layoutSub) {
            this.layoutSub.unsubscribe();
        }
        if (this.configSub) {
            this.configSub.unsubscribe();
        }
    }

}
