<div class="wrapper">
    <div class="main-panel">
        <!-- BEGIN : Main Content-->
        <div class="main-content">
            <div class="content-overlay"></div>
            <div class="content-wrapper p-0" >

                <!--Forgot Password Starts-->
                <section id="forgot-password">
                    <div class="row auth-height full-height-vh m-0 d-flex align-items-center justify-content-center">
                        <div class="col-md-7 col-12">
                            <div class="card overflow-hidden">
                                <div class="card-content">
                                    <div class="card-body auth-img">
                                        <div class="row m-0">
                                            <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center text-center auth-img-bg py-2">
                                                <img src="assets/img/gallery/forgot.png" alt="" class="img-fluid" width="260" height="230">
                                            </div>

                                            <div class="col-lg-6 col-md-12 px-4 py-3">
                                                <div class="logo-img" style="text-align: center;"><img class="logo-img" alt="WRCMS" [src]="logoUrl" style="max-width: 100px; padding-bottom: 2rem;"></div>

                                                <h4 class="mb-2 card-title">Recover Password</h4>
                                                <p class="card-text mb-3">Please enter your email address and we'll send you instructions on how to reset your password.</p>
                                                
                                                <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()">
                                                    <input type="text" formControlName="email" class="form-control mb-3" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Email">
                                                    <div *ngIf="submitted && f.email.errors">
                                                        <span class="text-error" *ngIf="f.email.errors.required">Email is required</span>
                                                        <span class="text-error" *ngIf="f.email.errors.email">Email must be a valid email address</span>
                                                    </div>
                                                
                                                    <div class="d-flex flex-sm-row flex-column justify-content-between">
                                                        <a [routerLink]="['/login']" class="btn bg-light-primary mb-2 mb-sm-0">Back To Login</a>
                                                        
                                                        <button type="submit" class="btn btn-primary "><a class="text-decoration-none text-white">Recover</a></button>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <!--Forgot Password Ends-->

            </div>
        </div>
    </div>
</div>