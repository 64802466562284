import { Component, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    subscription: Subscription;
    title = 'WRCMS';

    constructor(private router: Router, private route: ActivatedRoute, private titleService: Title) {
    }

    ngOnInit() {
        this.subscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => window.scrollTo(0, 0));

        this.subscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            ).subscribe(() => {
                
                window.scrollTo(0, 0)

                let route = this.route;
                while (route.firstChild) route = route.firstChild;
                route.data.subscribe(data => {
                    if(data.title) {
                        this.titleService.setTitle(data.title + ' | ' + this.title)
                    } else {
                        this.titleService.setTitle(this.title)
                    }
                });
            });
    }


    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}