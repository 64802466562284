import { Component, OnInit, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { HROUTES } from './navigation-routes.config';
import { LayoutService } from '../services/layout.service';
import { ConfigService } from '../services/config.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { RestService } from '../../services/rest.service';


@Component({
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss']
})
export class HorizontalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

    public menuItems: any[];
    public config: any = {};
    level: number = 0;
    transparentBGClass = "";
    menuPosition = 'Side';
    loggedUser: any;

    layoutSub: Subscription;

    constructor(
        private layoutService: LayoutService,
        private configService: ConfigService,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private rest: RestService,
    ) {
        this.config = this.configService.templateConf;
    }

    ngOnInit() {
        // this.menuItems = HROUTES;
        this.menuItems = [];

        this.rest.checkAuthentication().then((res) => {
            this.loggedUser = res;

            this.menuItems.push({ path: '/dashboard', title: 'Dashboard', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [ ] });

            if(this.loggedUser.permission.indexOf('data-entries-list') != -1) {
                this.menuItems.push({ path: '/data/list', title: 'Data', icon: 'fa fa-database', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [ ] });
            }

            if(this.loggedUser.permission.indexOf('data-manager-list') != -1) {
                this.menuItems.push({ path: '/data-manager/list', title: 'Media Data', icon: 'fa fa-database', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [ ] });
            }

            if(this.loggedUser.permission.indexOf('report-summery-bill') != -1) {
                this.menuItems.push({ path: '/report/summery-bill', title: 'Summery Bill Report', icon: 'fa fa-bar-chart', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [ ] });
            }
            if(this.loggedUser.permission.indexOf('report-ft') != -1) {
                this.menuItems.push({ path: '/report/ft', title: 'FT Report', icon: 'fa fa-bar-chart', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [ ] });
            }
            if(this.loggedUser.permission.indexOf('report-media-data') != -1) {
                this.menuItems.push({ path: '/report/media-data', title: 'Media Data Report', icon: 'fa fa-bar-chart', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [ ] });
            }

            let adminSubMenu = [];
            if(this.loggedUser.permission.indexOf('user-list') != -1) {
                adminSubMenu.push({ path: '/user/list', title: 'Users', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [ ] });
            }
            if(this.loggedUser.permission.indexOf('permission-list') != -1) {
                adminSubMenu.push({ path: '/user/permissions', title: 'Permissions', icon: 'ft-lock', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [ ] });
            }

            if(adminSubMenu && adminSubMenu.length) {
                this.menuItems.push({ path: '', title: 'Admin', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: adminSubMenu });
            } else {
                if(this.loggedUser.permission.indexOf('user-list') != -1) {
                    this.menuItems.push({ path: '/user/list', title: 'Users', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [ ] });
                }
            }

        }, (err) => {
            this.router.navigateByUrl('/');
        });
    }

    ngAfterViewInit() {

        this.layoutSub = this.configService.templateConf$.subscribe((templateConf) => {
            if (templateConf) {
                this.config = templateConf;
            }
            this.loadLayout();
            this.cdr.markForCheck();
        })
    }

    loadLayout() {
        if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() != "") {
            this.menuPosition = this.config.layout.menuPosition;
        }

        if (this.config.layout.variant === "Transparent") {
            this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
        } else {
            this.transparentBGClass = "";
        }
    }

    ngOnDestroy() {
        if (this.layoutSub) {
            this.layoutSub.unsubscribe();
        }
    }

}
