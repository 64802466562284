import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { RestService } from '../services/rest.service';
import { Router, NavigationExtras } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

	logoUrl = 'assets/img/logo.png';
	forgotPasswordForm: FormGroup;
	submitted = false;

	constructor(
		private rest: RestService,
		private router: Router,
		private formBuilder: FormBuilder,
		private alertService: ToastrService,
		private renderer: Renderer2,
		@Inject(DOCUMENT) private document: Document,
	) {
		this.renderer.addClass(this.document.body, "auth-page");
		this.renderer.removeClass(this.document.body, "layout-dark");
      	this.renderer.removeClass(this.document.body, "layout-transparent");
	}

	ngOnInit() {
		this.rest.checkAuthentication().then((res) => {
			this.router.navigateByUrl('/');
		}, (err) => {
		});

		this.forgotPasswordForm = this.formBuilder.group({
			email: ['',[Validators.required, Validators.email]],
		});
	}

    get f() { return this.forgotPasswordForm.controls; }

	forgotPassword() {

		this.submitted = true;
		if (this.forgotPasswordForm.invalid) {
			return;
		}

		this.rest.forgot(this.forgotPasswordForm.value).then((result) => {
			this.alertService.success(result['message'][0], '', { "progressBar": true });
			this.router.navigateByUrl('/login');
		}, (err) => {
			switch (err['status']) {
				case 300 :
					if(err.error && err.error.email && err.error.email.length ) {
						for (var row in err.error.email) {
							this.alertService.error(err.error.email[row], '', { "progressBar": true });
						}
					}
					if(err.error && err.error.message && err.error.message.length ) {
						for (var row in err.error.message) {
							this.alertService.error(err.error.message[row], '', { "progressBar": true });
						}
					}
					break;
				case 401 :
					// Do Nothing , login required
					if(err.error && err.error.message && err.error.message.length ) {
						for (var row in err.error.message) {
							this.alertService.error(err.error.message[row], '', { "progressBar": true });
						}
					}
					break;
				default :
					// Do Nothing , login required
					if(err.error && err.error.message && err.error.message.length ) {
						for (var row in err.error.message) {
							this.alertService.error(err.error.message[row], '', { "progressBar": true });
						}
					}
					break;
			}
		});
	}

}
