import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
	{
		path: 'dashboard',
		loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
	},{
		path: 'user',
		loadChildren: () => import('../../users/users.module').then(m => m.UsersModule)
	},{
		path: 'data',
		loadChildren: () => import('../../datas/datas.module').then(m => m.DatasModule)
	},{
		path: 'data-manager',
		loadChildren: () => import('../../data-manager/data-manager.module').then(m => m.DataManagerModule)
	},{
		path: 'report',
		loadChildren: () => import('../../reports/reports.module').then(m => m.ReportsModule)
	}
];
